<template>
  <div class="ma-5">
    <v-container>
      <v-card max-width="500" class="mx-auto mt-5">
        <div class="primary text-center white--text">
          <h1>Christore Admin</h1>
        </div>
        <v-card-title>
          <h1 class="title text-center">Login</h1>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Username"
            prepend-icon="mdi-account-circle"
            v-model="dform.username"
            required
          />
          <v-text-field
            label="Password"
            :type="showPasswd ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPasswd ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPasswd = !showPasswd"
            v-model="dform.passwd"
            required
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="primary"
            :to="{ name: 'NcaresApplicants' }"
            >Login</v-btn
          > -->
          <v-btn color="primary" :loading="loading" @click="processLogin"
            >Login</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" bottom color="error">
        <p>{{ invalidMsg }}</p>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import auth from "@/auth";
import { apiClient } from "@/services";
import { mapActions } from "vuex";

export default {
  name: "AdminLogin",
  data: () => ({
    showPasswd: false,
    dform: { username: "", passwd: "" },
    snackbar: false,
    loading: false,
    invalidMsg: "Invalid login details. Try again.",
  }),
  methods: {
    ...mapActions(["setLogin", "setCurUser"]),
    processLogin() {
      this.loading = true;
      apiClient
        .post("/login_admin", this.dform)
        .then((res) => {
          if (res.data.id) {
            this.setCurUser(res.data);
            this.setLogin(true);
            auth.login = true;
            const redirectPath = this.$route.query.redirect || {
              name: "Dashboard",
            };
            this.$router.push(redirectPath);
          } else {
            this.invalidMsg = res.data;
            this.snackbar = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.invalidMsg = err.message;
          this.snackbar = true;
          this.loading = false;
        });
    },
  },
};
</script>
